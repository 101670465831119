import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { ReactComponent as IconSVG } from "svg/bitskiIcon.svg"
import pointer from "svg/pointer.svg"
import media from "src/styles/media"

const MinimalHeader: React.FC<{}> = () => {
  return (
    <Wrapper>
      <Link to="/">
        <Icon />
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  height: 5.9vw;
  width: 100vw;
  max-width: 100%;
  padding: 0 6.9vw;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 5555;
  background: rgba(30, 30, 30, 0.75);
  ${media.tablet} {
    height: 20.3vw;
  }
`
const Icon = styled(IconSVG)`
  width: 2.7vw;
  cursor: url(${pointer}), auto;
  ${media.tablet} {
    width: 7.5vw;
  }
`

export default MinimalHeader
