import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { ReactComponent as LogoSVG } from "svg/logo.svg"
import { ReactComponent as TwitterSVG } from "svg/twitter.svg"
import { ReactComponent as DiscordSVG } from "svg/discord.svg"

import { NavButton } from "./Buttons"
import mouse from "svg/mouseArrow.svg"
import pointer from "svg/pointer.svg"
import media from "src/styles/media"

const Footer: React.FC<{}> = () => {
  return (
    <Wrapper>
      <MainContent>
        <CompanyInfo>
          <Link aria-label="home" to="/">
            <Logo />
          </Link>
          <Address>
            Out There Labs, Inc.
            <br />
            321 Everywhere Street <br />
            San Francisco, CA 84828
          </Address>
          <Socials>
            <a aria-label="twitter">
              <Twitter />
            </a>
            <a aria-label="discord">
              <Discord />
            </a>
          </Socials>
        </CompanyInfo>
        <List aria-label="SDKs">
          <li>
            <LinkExternal>Javascript</LinkExternal>
          </li>
          <li>
            <LinkExternal>Node.js</LinkExternal>
          </li>
          <li>
            <LinkExternal>Truffle</LinkExternal>
          </li>
          <li>
            <LinkExternal>iOS</LinkExternal>
          </li>
          <li>
            <LinkExternal>Unity</LinkExternal>
          </li>
        </List>
        <List aria-label="RESOURCES">
          <li>
            <LinkExternal>Blog</LinkExternal>
          </li>
          <li>
            <LinkExternal>Dev Docs</LinkExternal>
          </li>
          <li>
            <LinkExternal>Dev Portal</LinkExternal>
          </li>
          <li>
            <LinkExternal>FAQ</LinkExternal>
          </li>
          <li>
            <LinkExternal>GitHub</LinkExternal>
          </li>
        </List>
        <List aria-label="COMPANY">
          <li>
            <LinkExternal>Solutions</LinkExternal>
          </li>
          <li>
            <LinkExternal>Pricing</LinkExternal>
          </li>
          <li>
            <LinkExternal>Sign Up</LinkExternal>
          </li>
          <li>
            <LinkExternal>Contact Us</LinkExternal>
          </li>
        </List>
        <Button>Log In</Button>
      </MainContent>
      <Line>
        © Out There Labs, Inc.
        <LegalLinks>
          <LinkExternal>Terms of Use</LinkExternal>
          <LinkExternal>Privacy Policy</LinkExternal>
        </LegalLinks>
      </Line>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  cursor: url(${mouse}), auto;
  button,
  a,
  input[type="submit"] {
    cursor: url(${pointer}), auto !important;
  }
  height: 27.9vw;
  border-top: solid 1px white;
  padding: 6.2vw 6.94vw 0;
  ${media.tablet} {
    height: 258.2vw;
    padding: 19.3vw 4.8vw 0;
  }
`
const MainContent = styled.div`
  display: flex;
  align-items: flex-start;
  ${media.tablet} {
    flex-wrap: wrap;
  }
`
const CompanyInfo = styled.div`
  width: 11.7vw;
  margin-right: 40vw;
  ${media.tablet} {
    order: 5;
    width: 40.8vw;
  }
`
const Logo = styled(LogoSVG)`
  width: 7.2vw;
  ${media.tablet} {
    width: 25.1vw;
  }
`
const Address = styled.p`
  font-size: 0.8vw;
  margin-top: 1.4vw;
  line-height: 130.5%;
  ${media.tablet} {
    font-size: 2.9vw;
    margin-top: 4.8vw;
  }
`
const Socials = styled.div`
  display: flex;
  margin-top: 1.4vw;
  ${media.tablet} {
    margin-top: 7vw;
  }
`
const Twitter = styled(TwitterSVG)`
  width: 1.5vw;
  margin-right: 1.4vw;
  ${media.tablet} {
    width: 8.9vw;
    margin-right: 8vw;
  }
`
const Discord = styled(DiscordSVG)`
  width: 1.4vw;
  ${media.tablet} {
    width: 8.9vw;
  }
`
const List = styled.ul`
  font-size: 0.8vw;
  line-height: 1.2857142857142858em;
  width: 5.8vw;
  margin-right: 2vw;
  padding-top: 1.4vw;
  position: relative;
  li {
    margin-top: 0.8vw;
  }
  :before {
    content: attr(aria-label);
    font-weight: 300;
    position: absolute;
    top: 0;
  }
  ${media.tablet} {
    font-size: 3.3vw;
    width: 23.7vw;
    padding-top: 4.4vw;
    margin-bottom: 18.1vw;
    &:nth-child(2) {
      margin-right: 27.5vw;
    }
    li {
      margin-top: 6vw;
    }
    :before {
      font-size: 4.3vw;
    }
  }
`
const LinkExternal = styled.a`
  color: white;
`

const Button = styled(NavButton)`
  width: 10vw;
  height: 2.6vw;
  text-transform: uppercase;
  margin-left: 1.7vw;
  ${media.tablet} {
    order: 4;
    width: 100%;
    height: 12.1vw;
    margin-bottom: 18.1vw;
  }
`
const Line = styled.div`
  width: 86.1vw;
  border-top: solid 1px white;
  margin-top: 8vw;
  display: flex;
  padding-top: 0.9vw;
  justify-content: space-between;
  font-size: 0.7vw;
  ${media.tablet} {
    width: 100%;
    margin-top: 6.5vw;
    padding-top: 7.2vw;
    font-size: 2.4vw;
  }
`
const LegalLinks = styled.div`
  display: flex;
  width: 12.9vw;
  justify-content: space-between;
  ${media.tablet} {
    width: 42.5vw;
  }
`
export default Footer
